@import "~/styles/responsive.scss";

.newsletter {
  margin-top: 2rem;
  padding: 2rem 0;
  color: var(--grey-50);
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .email_box {
    padding: 1.25rem 2.5rem;
    border-radius: 0.75rem;
    border: 1px solid rgba(255, 255, 255, 0.28);
    background: rgba(231, 225, 225, 0.14);
    backdrop-filter: blur(15.3px);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    // min-height: 230px;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    z-index: 0;
    width: 100%;

    @include screen(lg) {
      width: 50%;
    }

    &:before {
      content: "";
      position: absolute;
      top: -60%;
      left: -30%;
      width: 969px;
      aspect-ratio: 1;
      transform: translate(-50%, -50%);
      z-index: -1;
      border-radius: 100%;
      opacity: 0.8;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(174, 0, 10, 0.69) 0%,
        rgba(14, 6, 1, 0) 100%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .title {
    color: white;
    font-weight: 900;
    line-height: 1.2;
    font-size: 2rem;

    @include screen(lg) {
      font-size: 3.5rem;
    }
  }
}
